import { Middleware, Reducer, Store, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import thunkMiddleware from 'redux-thunk';

import { AccrualReportState, accrualReportReducer } from '@accrual-report';
import api from '@api';
import { BalanceReportState, balanceReportReducer } from '@balance-report';
import { BusinessUnitsState, businessUnitsEffects, businessUnitsReducer } from '@business-units';
import { CashflowItemsState, cashflowItemsManagementEffects, cashflowItemsReducer } from '@cashflow-items';
import { CashflowLimitsState, cashflowLimitsReducer } from '@cashflow-limits';
import {
	CashflowPaymentRulesState,
	cashflowPaymentRulesEffects,
	cashflowPaymentRulesReducer,
} from '@cashflow-payment-rules';
import { CashflowReportState, cashflowReportReducer } from '@cashflow-report';
import { ClientsState, clientsEffects, clientsReducer } from '@clients';
import { CounterpartiesState, counterpartiesEffects, counterpartiesReducer } from '@counterparties';
import { BplState, bplReducer } from '@dashboard';
import { DocumentTemplatesState, documentTemplatesEffects, documentTemplatesReducer } from '@document-templates';
import { DocumentsState, documentsReducer } from '@documents';
import { EmployeesState, employeesEffects, employeesReducer } from '@employees';
import {
	FundsRegisterOpenRequestsState,
	fundsRegisterOpenRequestsReducer,
} from '@funds-register-open-requests/reducers';
import { FundsRegistersState, fundsRegistersReducer } from '@funds-registers';
import { fundsRegistersEffects } from '@funds-registers/effects';
import { InitializationState, initializationEffects, initializationReducer } from '@initialization';
import { IntegrationsState, integrationsReducer } from '@integrations';
import { integrationsEffects } from '@integrations/effects';
import { InvoicesState, invoicesEffects, invoicesReducer } from '@invoices';
import { MeasureUnitsState, measureUnitsEffects, measureUnitsReducer } from '@measure-units';
import { PaymentRulesState, paymentRulesEffects, paymentRulesReducer } from '@payment-rules';
import { PaymentsState, paymentsReducer } from '@payments';
import { paymentsEffects } from '@payments/effects';
import { OperationsState, operationsEffects, operationsReducer } from '@pl';
import { PlAnalyticsState } from '@pl-analytics';
import { PLPlanningState, plPlanningReducer } from '@pl-planning';
import { PlanningState, planningReducer } from '@planning';
import { ProductsState, productsReducer } from '@products';
import { ProfitLossReportState, profitLossReportReducer } from '@profit-loss-report';
import { ProjectEmployeesState, projectEmployeesEffects, projectEmployeesReducer } from '@project-employees';
import { ProjectManagementState, projectManagementEffects, projectManagementReducer } from '@project-management';
import {
	ProjectPaymentRulesState,
	projectPaymentRulesEffects,
	projectPaymentRulesReducer,
} from '@project-payment-rules';
import { ReconciliationActState, reconciliationActReducer } from '@reconciliation-act';
import { ReferenceState, referenceReducer } from '@reference';
import {
	TenantLegalEntitiesState,
	tenantLegalEntitiesEffects,
	tenantLegalEntitiesReducer,
} from '@tenant-legal-entities';
import { TopCounterpartiesReportState, topCounterpartiesReportReducer } from '@top-counterparties-report';
import { TransactionsState, transactionsReducer } from '@transactions';
import { TransferRulesState, transferRulesEffects, transferRulesReducer } from '@transfer-rules';
import analytics, { IAnalyticsStore } from '../modules/analytics';
import invoice, { IInvoiceStore } from '../modules/invoice';
import { invoiceEffects } from '../modules/invoice/effects';
import { IPlatformState, platformReducer } from '../modules/platform';
import { platformEffects } from '../modules/platform/effects';
import shared, { SharedState } from '../modules/shared';
import { sharedEffects } from '../modules/shared/effects';

export type IAppState = {
	accrualReport: AccrualReportState;
	analytics: IAnalyticsStore.store;
	balanceReport: BalanceReportState;
	bpl: BplState;
	businessUnits: BusinessUnitsState;
	cashflowItems: CashflowItemsState;
	cashflowLimits: CashflowLimitsState;
	cashflowPaymentRules: CashflowPaymentRulesState;
	cashflowReport: CashflowReportState;
	clients: ClientsState;
	counterparties: CounterpartiesState;
	documents: DocumentsState;
	documentTemplates: DocumentTemplatesState;
	employees: EmployeesState;
	fundsRegisterOpenRequests: FundsRegisterOpenRequestsState;
	fundsRegisters: FundsRegistersState;
	initialization: InitializationState;
	integrations: IntegrationsState;
	invoice: IInvoiceStore.store;
	invoices: InvoicesState;
	measureUnits: MeasureUnitsState;
	operations: OperationsState;
	paymentRules: PaymentRulesState;
	payments: PaymentsState;
	plAnalytics?: PlAnalyticsState; // async reducer
	planning: PlanningState;
	platform: IPlatformState;
	plPlanning: PLPlanningState;
	products: ProductsState;
	profitLossReport: ProfitLossReportState;
	projectEmployees: ProjectEmployeesState;
	projectManagement: ProjectManagementState;
	projectPaymentRules: ProjectPaymentRulesState;
	reconciliationAct: ReconciliationActState;
	reference: ReferenceState;
	shared: SharedState;
	tenantLegalEntities: TenantLegalEntitiesState;
	topCounterpartiesReport: TopCounterpartiesReportState;
	transactions: TransactionsState;
	transferRules: TransferRulesState;
};

const staticReducers = {
	accrualReport: accrualReportReducer,
	analytics: analytics.reducer,
	balanceReport: balanceReportReducer,
	bpl: bplReducer,
	businessUnits: businessUnitsReducer,
	cashflowItems: cashflowItemsReducer,
	cashflowLimits: cashflowLimitsReducer,
	cashflowPaymentRules: cashflowPaymentRulesReducer,
	cashflowReport: cashflowReportReducer,
	clients: clientsReducer,
	counterparties: counterpartiesReducer,
	documents: documentsReducer,
	documentTemplates: documentTemplatesReducer,
	employees: employeesReducer,
	fundsRegisterOpenRequests: fundsRegisterOpenRequestsReducer,
	fundsRegisters: fundsRegistersReducer,
	initialization: initializationReducer,
	integrations: integrationsReducer,
	invoice: invoice.reducer,
	invoices: invoicesReducer,
	measureUnits: measureUnitsReducer,
	operations: operationsReducer,
	paymentRules: paymentRulesReducer,
	payments: paymentsReducer,
	planning: planningReducer,
	platform: platformReducer,
	plPlanning: plPlanningReducer,
	products: productsReducer,
	profitLossReport: profitLossReportReducer,
	projectEmployees: projectEmployeesReducer,
	projectManagement: projectManagementReducer,
	projectPaymentRules: projectPaymentRulesReducer,
	reconciliationAct: reconciliationActReducer,
	reference: referenceReducer,
	shared: shared.reducer,
	tenantLegalEntities: tenantLegalEntitiesReducer,
	topCounterpartiesReport: topCounterpartiesReportReducer,
	transactions: transactionsReducer,
	transferRules: transferRulesReducer,
};

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;

const middlewares = [
	thunkMiddleware.withExtraArgument(api),
	...platformEffects,
	...initializationEffects,
	...sharedEffects,
	...invoiceEffects,
	...operationsEffects,
	...cashflowPaymentRulesEffects,
	...projectPaymentRulesEffects,
	...transferRulesEffects,
	...projectManagementEffects,
	...projectEmployeesEffects,
	...invoicesEffects,
	...documentTemplatesEffects,
	...clientsEffects,
	...integrationsEffects,
	...fundsRegistersEffects,
	...paymentsEffects,
	...businessUnitsEffects,
	...measureUnitsEffects,
	...counterpartiesEffects,
	...cashflowItemsManagementEffects,
	...tenantLegalEntitiesEffects,
	...employeesEffects,
	...paymentRulesEffects,
] as Array<Middleware>;

const enhancers = compose(applyMiddleware(...middlewares, dynamicMiddlewares), devTools);

type EnhancedStore<T> = {
	asyncReducers: Record<string, Reducer>;
	injectReducer: (key: string, asyncReducer: Reducer) => void;
} & Store<T>;

const configureStore = () => {
	const store = createStore(createReducer(), enhancers) as EnhancedStore<IAppState>;

	store.asyncReducers = {};

	store.injectReducer = (key, asyncReducer) => {
		if (store.asyncReducers[key] === asyncReducer) return;
		store.asyncReducers[key] = asyncReducer;
		store.replaceReducer(createReducer(store.asyncReducers));
	};

	return store;
};

function createReducer(asyncReducers: Record<string, Reducer> = {}) {
	return combineReducers<IAppState>({
		...staticReducers,
		...asyncReducers,
	});
}

const store = configureStore();

export default store;
