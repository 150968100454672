import axios from 'axios';

const API_ENDPOINT = 'https://api.carrotquest.io/';

const carrotquestApi = {
	startConversation: (message: string, needOpen = true) => {
		const isCarrotquest = Boolean(window.carrotquest);

		if (!isCarrotquest) return;

		const { auth_token: token, user } = window.carrotquest.data;
		const payload = {
			auth_token: token,
			body: message,
		};
		const data = new FormData();

		Object.keys(payload).forEach(key => {
			data.append(key, payload[key]);
		});

		return new Promise<void>(async (resolve, reject) => {
			axios({
				data,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				method: 'post',
				url: `${API_ENDPOINT}v1/users/${user.id}/startconversation`,
			})
				.then(() => {
					needOpen && window.carrotquest.open();
					resolve();
				})
				.catch(error => {
					reject(error);
				});
		});
	},
};

export { carrotquestApi };
