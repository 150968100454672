/***
 * Library contains JavaScript API for interaction with Seeneco Framework Web Services contained in PlPackage
 * @author vyasinskiy Generated by Seeneco Framework Modeler 
 *
 */
(function(w){
  /***
  * 
  */
  w.CardTransaction = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "CARD_TRANSACTION";
    this.CopySupportingElement = false;
    this.Amount = -1;
    this.ID = -1;
    this.Description = "";
    this.AccrualDate = null;
    this.Expense = false;
    this.AccountsChartItem = null;
    this.Project = null;
    this.CashflowDate = null;
    this.FundsRegister = null;
    this.CurrencyAmount = -1;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.BusinessUnit = null;
    this.InternalCashFlow = false;
    this.Counterparty = null;
    this.ResponsibleEmployee = null;
    this.TenantLegalEntity = null;
    this.Status = -1;
    this.Split = false;
    this.LimitedAccess = false;
    this.HasPaymentDraft = false;
    this.SupportCreatePaymentDraft = false;
    this.Comment = "";
    this.Personal = false;
    this.PLOperationTemplateID = -1;
    this.CurrencyVatValue = -1;
    this.UID = "";
    this.AcquiringCommission = -1;
  };
  /***
  * 
  */
  w.CounterpartiesTopRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "COUNTERPARTIES_TOP_REQUEST";
    this.CopySupportingElement = false;
    this.Count = -1;
    this.OwnLegalEntityAggregation = false;
    this.DateStart = null;
    this.IntervalType = "";
    this.DateFinish = null;
    this.CashflowDateAggregation = false;
    this.CashflowTypes = [];
    this.TenantLegalEntities = [];
    this.InConsolidation = false;
    this.InCFConsolidation = false;
    this.InPLConsolidation = false;
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.ExcludingVat = false;
    this.ExcludingPlan = false;
    this.InDashboardConsolidation = false;
    this.BusinessUnitsIDs = [];
    this.Personal = -1;
    this.ExcludingInternalCashflow = false;
  };
  /***
  * 
  */
  w.CounterpartiesTopResponse = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "COUNTERPARTIES_TOP_RESPONSE";
    this.CopySupportingElement = false;
    this.TopPayers = [];
    this.OtherPayers = [];
    this.OtherPayerIdList = [];
    this.TopRecipients = [];
    this.OtherRecipients = [];
    this.OtherRecipientIdList = [];
  };
  /***
  * 
  */
  w.DirectionFilter = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "DIRECTION_FILTER";
    this.CopySupportingElement = false;
    this.Income = false;
    this.Expense = false;
    this.Transfer = false;
  };
  /***
  * Правила определения статей ДДС в соответствии с типом операции маркетплейса
  */
  w.MarketplaceOperationRule = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "MARKETPLACE_OPERATION_RULE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.AccountsChartItem = null;
    this.BusinessUnit = null;
    this.Project = null;
    this.MarketplaceOperationType = null;
  };
  /***
  * 
  */
  w.MarketplaceOperationType = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "MARKETPLACE_OPERATION_TYPE";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.IsService = false;
    this.MarketplaceID = -1;
    this.Code = "";
    this.ID = -1;
    this.Name = "";
    this.GUID = "";
  };
  /***
  * 
  */
  w.MassUpdateOperationsProps = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "MASS_UPDATE_OPERATIONS_PROPS";
    this.CopySupportingElement = false;
    this.AccrualDate = null;
    this.AccountsChartItem = null;
    this.Project = null;
    this.BusinessUnit = null;
    this.Personal = -1;
  };
  /***
  * 
  */
  w.PLOperation = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION";
    this.CopySupportingElement = false;
    this.SettlementType = null;
    this.Payment = null;
    this.PLOperationSchedulePartID = -1;
    this.Amount = -1;
    this.ID = -1;
    this.Description = "";
    this.AccrualDate = null;
    this.Expense = false;
    this.AccountsChartItem = null;
    this.Project = null;
    this.CashflowDate = null;
    this.FundsRegister = null;
    this.CurrencyAmount = -1;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.BusinessUnit = null;
    this.InternalCashFlow = false;
    this.Counterparty = null;
    this.ResponsibleEmployee = null;
    this.TenantLegalEntity = null;
    this.Status = -1;
    this.Split = false;
    this.LimitedAccess = false;
    this.HasPaymentDraft = false;
    this.SupportCreatePaymentDraft = false;
    this.Comment = "";
    this.Personal = false;
    this.PLOperationTemplateID = -1;
    this.CurrencyVatValue = -1;
    this.UID = "";
    this.AcquiringCommission = -1;
  };
  /***
  * 
  */
  w.PLOperationAccountsChartItemDynamicsRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_ACCOUNTS_CHART_ITEM_DYNAMICS_REQUEST";
    this.CopySupportingElement = false;
    this.AccountsChartItemList = [];
    this.DateStart = null;
    this.IntervalType = "";
    this.DateFinish = null;
    this.CashflowDateAggregation = false;
    this.CashflowTypes = [];
    this.TenantLegalEntities = [];
    this.InConsolidation = false;
    this.InCFConsolidation = false;
    this.InPLConsolidation = false;
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.ExcludingVat = false;
    this.ExcludingPlan = false;
    this.InDashboardConsolidation = false;
    this.BusinessUnitsIDs = [];
    this.Personal = -1;
    this.ExcludingInternalCashflow = false;
  };
  /***
  * 
  */
  w.PLOperationAccountsChartItemSeries = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_ACCOUNTS_CHART_ITEM_SERIES";
    this.CopySupportingElement = false;
    this.AccountsChartItemID = -1;
    this.AccountsChartItemName = "";
    this.Points = [];
  };
  /***
  * 
  */
  w.PLOperationACIRoleDynamicsRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_ACIROLE_DYNAMICS_REQUEST";
    this.CopySupportingElement = false;
    this.AccountsChartItemRoleList = [];
    this.DateStart = null;
    this.IntervalType = "";
    this.DateFinish = null;
    this.CashflowDateAggregation = false;
    this.CashflowTypes = [];
    this.TenantLegalEntities = [];
    this.InConsolidation = false;
    this.InCFConsolidation = false;
    this.InPLConsolidation = false;
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.ExcludingVat = false;
    this.ExcludingPlan = false;
    this.InDashboardConsolidation = false;
    this.BusinessUnitsIDs = [];
    this.Personal = -1;
    this.ExcludingInternalCashflow = false;
  };
  /***
  * 
  */
  w.PLOperationACIRoleSeries = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_ACIROLE_SERIES";
    this.CopySupportingElement = false;
    this.RoleID = -1;
    this.RoleName = "";
    this.Points = [];
  };
  /***
  * 
  */
  w.PLOperationBase = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_BASE";
    this.CopySupportingElement = false;
    this.Amount = -1;
    this.ID = -1;
    this.Description = "";
    this.AccrualDate = null;
    this.Expense = false;
    this.AccountsChartItem = null;
    this.Project = null;
    this.CashflowDate = null;
    this.FundsRegister = null;
    this.CurrencyAmount = -1;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.BusinessUnit = null;
    this.InternalCashFlow = false;
    this.Counterparty = null;
    this.ResponsibleEmployee = null;
    this.TenantLegalEntity = null;
    this.Status = -1;
    this.Split = false;
    this.LimitedAccess = false;
    this.HasPaymentDraft = false;
    this.SupportCreatePaymentDraft = false;
    this.Comment = "";
    this.Personal = false;
    this.PLOperationTemplateID = -1;
    this.CurrencyVatValue = -1;
    this.UID = "";
    this.AcquiringCommission = -1;
  };
  /***
  * 
  */
  w.PLOperationBrief = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_BRIEF";
    this.CopySupportingElement = false;
    this.PaymentID = -1;
    this.MirrorPaymentID = -1;
    this.SameDirectionTransferStrategy = false;
    this.Amount = -1;
    this.ID = -1;
    this.Description = "";
    this.AccrualDate = null;
    this.Expense = false;
    this.AccountsChartItem = null;
    this.Project = null;
    this.CashflowDate = null;
    this.FundsRegister = null;
    this.CurrencyAmount = -1;
    this.VatValue = -1;
    this.VatPercent = -1;
    this.BusinessUnit = null;
    this.InternalCashFlow = false;
    this.Counterparty = null;
    this.ResponsibleEmployee = null;
    this.TenantLegalEntity = null;
    this.Status = -1;
    this.Split = false;
    this.LimitedAccess = false;
    this.HasPaymentDraft = false;
    this.SupportCreatePaymentDraft = false;
    this.Comment = "";
    this.Personal = false;
    this.PLOperationTemplateID = -1;
    this.CurrencyVatValue = -1;
    this.UID = "";
    this.AcquiringCommission = -1;
  };
  /***
  * 
  */
  w.PLOperationBusinessUnitDynamicsRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_BUSINESS_UNIT_DYNAMICS_REQUEST";
    this.CopySupportingElement = false;
    this.BusinessUnitList = [];
    this.DateStart = null;
    this.IntervalType = "";
    this.DateFinish = null;
    this.CashflowDateAggregation = false;
    this.CashflowTypes = [];
    this.TenantLegalEntities = [];
    this.InConsolidation = false;
    this.InCFConsolidation = false;
    this.InPLConsolidation = false;
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.ExcludingVat = false;
    this.ExcludingPlan = false;
    this.InDashboardConsolidation = false;
    this.BusinessUnitsIDs = [];
    this.Personal = -1;
    this.ExcludingInternalCashflow = false;
  };
  /***
  * 
  */
  w.PLOperationBusinessUnitSeries = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_BUSINESS_UNIT_SERIES";
    this.CopySupportingElement = false;
    this.BusinessUnitID = -1;
    this.BusinessUnitName = "";
    this.Points = [];
  };
  /***
  * 
  */
  w.PLOperationChangeSet = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_CHANGE_SET";
    this.CopySupportingElement = false;
    this.AddedOperations = [];
    this.ChangedOperations = [];
    this.RemovedOperations = [];
  };
  /***
  * 
  */
  w.PLOperationCounterpartyDynamicsRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_COUNTERPARTY_DYNAMICS_REQUEST";
    this.CopySupportingElement = false;
    this.CounterpartyList = [];
    this.DateStart = null;
    this.IntervalType = "";
    this.DateFinish = null;
    this.CashflowDateAggregation = false;
    this.CashflowTypes = [];
    this.TenantLegalEntities = [];
    this.InConsolidation = false;
    this.InCFConsolidation = false;
    this.InPLConsolidation = false;
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.ExcludingVat = false;
    this.ExcludingPlan = false;
    this.InDashboardConsolidation = false;
    this.BusinessUnitsIDs = [];
    this.Personal = -1;
    this.ExcludingInternalCashflow = false;
  };
  /***
  * 
  */
  w.PLOperationCounterpartySeries = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_COUNTERPARTY_SERIES";
    this.CopySupportingElement = false;
    this.CounterpartyID = -1;
    this.CounterpartyName = "";
    this.Points = [];
  };
  /***
  * 
  */
  w.PLOperationDirectionDynamicsRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_DIRECTION_DYNAMICS_REQUEST";
    this.CopySupportingElement = false;
    this.DateStart = null;
    this.IntervalType = "";
    this.DateFinish = null;
    this.CashflowDateAggregation = false;
    this.CashflowTypes = [];
    this.TenantLegalEntities = [];
    this.InConsolidation = false;
    this.InCFConsolidation = false;
    this.InPLConsolidation = false;
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.ExcludingVat = false;
    this.ExcludingPlan = false;
    this.InDashboardConsolidation = false;
    this.BusinessUnitsIDs = [];
    this.Personal = -1;
    this.ExcludingInternalCashflow = false;
  };
  /***
  * 
  */
  w.PLOperationDirectionSeries = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_DIRECTION_SERIES";
    this.CopySupportingElement = false;
    this.AddedOperations = null;
    this.Points = [];
  };
  /***
  * 
  */
  w.PLOperationDynamics = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_DYNAMICS";
    this.CopySupportingElement = false;
    this.Series = [];
    this.Request = null;
  };
  /***
  * 
  */
  w.PLOperationDynamicsRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_DYNAMICS_REQUEST";
    this.CopySupportingElement = false;
    this.DateStart = null;
    this.IntervalType = "";
    this.DateFinish = null;
    this.CashflowDateAggregation = false;
    this.CashflowTypes = [];
    this.TenantLegalEntities = [];
    this.InConsolidation = false;
    this.InCFConsolidation = false;
    this.InPLConsolidation = false;
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.ExcludingVat = false;
    this.ExcludingPlan = false;
    this.InDashboardConsolidation = false;
    this.BusinessUnitsIDs = [];
    this.Personal = -1;
    this.ExcludingInternalCashflow = false;
  };
  /***
  * 
  */
  w.PLOperationListRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_LIST_REQUEST";
    this.CopySupportingElement = false;
    this.DateFrom = null;
    this.DateTo = null;
    this.CounterpartyIDList = [];
    this.AccountsChartItemIDList = [];
    this.ProjectIDList = [];
    this.TenantLegalEntityIDList = [];
    this.FundsRegisterIDList = [];
    this.SearchQuery = "";
    this.StatusFilter = null;
    this.DirectionFilter = null;
    this.PLOperationTemplateID = -1;
    this.AccrualDateFilter = false;
    this.InConsolidation = false;
    this.InCFConsolidation = false;
    this.InPLConsolidation = false;
    this.InProjectConsolidation = false;
    this.ResponsibleEmployeeIDList = [];
    this.CashflowTypeIDList = [];
    this.ExcludingVat = false;
    this.BusinessUnitsIDs = [];
    this.Personal = -1;
    this.InABCAnalysis = false;
  };
  /***
  * 
  */
  w.PLOperationPlanningReportColumn = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_PLANNING_REPORT_COLUMN";
    this.CopySupportingElement = false;
    this.ValueDate = null;
    this.Code = "";
    this.FactAmount = -1;
    this.PlanAmount = -1;
  };
  /***
  * 
  */
  w.PLOperationPlanningReportItem = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_PLANNING_REPORT_ITEM";
    this.CopySupportingElement = false;
    this.ID = -1;
    this.Name = "";
    this.ParentID = -1;
    this.Columns = [];
    this.ChildItemIDs = "";
    this.Incoming = false;
    this.ChildItemIDsCSV = "";
    this.AllChildItemIDsCSV = "";
  };
  /***
  * 
  */
  w.PLOperationPlanningReportRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_PLANNING_REPORT_REQUEST";
    this.CopySupportingElement = false;
    this.DateStart = null;
    this.DateFinish = null;
    this.GroupingType = "";
    this.BusinessUnitIDList = [];
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.CounterpartyIDList = [];
    this.AccountsChartItemIDList = [];
    this.TenantLegalEntityIDList = [];
    this.DateType = "";
  };
  /***
  * 
  */
  w.PLOperationPlanningReportResponse = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_PLANNING_REPORT_RESPONSE";
    this.CopySupportingElement = false;
    this.Request = null;
    this.Items = [];
  };
  /***
  * 
  */
  w.PLOperationProjectDynamicsRequest = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_PROJECT_DYNAMICS_REQUEST";
    this.CopySupportingElement = false;
    this.ProjectList = [];
    this.InProjectConsolidation = false;
    this.DateStart = null;
    this.IntervalType = "";
    this.DateFinish = null;
    this.CashflowDateAggregation = false;
    this.CashflowTypes = [];
    this.TenantLegalEntities = [];
    this.InConsolidation = false;
    this.InCFConsolidation = false;
    this.InPLConsolidation = false;
    this.FundsRegisterIDList = [];
    this.ProjectIDList = [];
    this.ExcludingVat = false;
    this.ExcludingPlan = false;
    this.InDashboardConsolidation = false;
    this.BusinessUnitsIDs = [];
    this.Personal = -1;
    this.ExcludingInternalCashflow = false;
  };
  /***
  * 
  */
  w.PLOperationProjectSeries = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_PROJECT_SERIES";
    this.CopySupportingElement = false;
    this.ProjectID = -1;
    this.ProjectName = "";
    this.Points = [];
  };
  /***
  * 
  */
  w.PLOperationSchedulePart = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_SCHEDULE_PART";
    this.CopySupportingElement = false;
    this.Amount = -1;
    this.FirstOperationDate = null;
    this.PeriodType = "";
    this.LastOperationDate = null;
    this.ID = -1;
    this.Operations = [];
    this.VatValue = -1;
    this.VatPercent = -1;
    this.CurrencyVatValue = -1;
  };
  /***
  * 
  */
  w.PLOperationTemplate = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_TEMPLATE";
    this.CopySupportingElement = false;
    this.Reference = "";
    this.ID = -1;
    this.ScheduleParts = [];
    this.CounterpartyID = -1;
    this.AccountsChartItemID = -1;
    this.SettlementTypeID = -1;
    this.ResponsibleEmployeeID = -1;
    this.TenantLegalEntityID = -1;
    this.ProjectID = -1;
    this.Expense = false;
    this.FundsRegisterID = -1;
    this.BusinessUnitID = -1;
    this.Personal = false;
  };
  /***
  * 
  */
  w.PLOperationTimeSeries = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_TIME_SERIES";
    this.CopySupportingElement = false;
    this.Points = [];
  };
  /***
  * 
  */
  w.PLOperationTimeSeriesPoint = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLOPERATION_TIME_SERIES_POINT";
    this.CopySupportingElement = false;
    this.ValueDate = null;
    this.Amount = -1;
    this.Plan = false;
    this.DirectionIncoming = false;
    this.HasExpiredPlan = false;
  };
  /***
  * 
  */
  w.PLSummaryRecord = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "PLSUMMARY_RECORD";
    this.CopySupportingElement = false;
    this.ValueDate = null;
    this.Amount = -1;
    this.Plan = false;
    this.Expense = false;
  };
  /***
  * 
  */
  w.SendPaymentDraftResponse = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "SEND_PAYMENT_DRAFT_RESPONSE";
    this.CopySupportingElement = false;
    this.PLOperationID = -1;
    this.Status = "";
    this.Description = "";
  };
  /***
  * 
  */
  w.StatusFilter = function() {
    this.PACKAGE = "PL";
    this.CLASSIFIER = "STATUS_FILTER";
    this.CopySupportingElement = false;
    this.Plan = false;
    this.Fact = false;
  };
   
   
  
  /***
   * Client API to MarketplaceOperationRuleServiceWS web service
   */
  w.MarketplaceOperationRuleServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "MarketplaceOperationRuleServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param rule [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.MarketplaceOperationRuleServiceClient.prototype.addRule = function(rule, callbackHandler) {
    var parameterNames = ["rule"];
    var ruleJSON = JSON.stringify(rule);
    var parameterValues = [ruleJSON];
    var soapMessage = buildSOAPMessage("addRule", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param ruleID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.MarketplaceOperationRuleServiceClient.prototype.removeRule = function(ruleID, callbackHandler) {
    var parameterNames = ["ruleID"];
    var parameterValues = [ruleID];
    var soapMessage = buildSOAPMessage("removeRule", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param rule [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.MarketplaceOperationRuleServiceClient.prototype.updateRule = function(rule, callbackHandler) {
    var parameterNames = ["rule"];
    var ruleJSON = JSON.stringify(rule);
    var parameterValues = [ruleJSON];
    var soapMessage = buildSOAPMessage("updateRule", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.MarketplaceOperationRuleServiceClient.prototype.getRules = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("getRules", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * Client API to PLOperationServiceWS web service
   */
  w.PLOperationServiceClient = function() {
    /***
     * System name of target service
     */
    this.serviceName = "PLOperationServiceWSImpl";
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operation [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.addOperation = function(operation, callbackHandler) {
    var parameterNames = ["operation"];
    var operationJSON = JSON.stringify(operation);
    var parameterValues = [operationJSON];
    var soapMessage = buildSOAPMessage("addOperation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operation [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.updateOperation = function(operation, callbackHandler) {
    var parameterNames = ["operation"];
    var operationJSON = JSON.stringify(operation);
    var parameterValues = [operationJSON];
    var soapMessage = buildSOAPMessage("updateOperation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.removeOperation = function(operationID, callbackHandler) {
    var parameterNames = ["operationID"];
    var parameterValues = [operationID];
    var soapMessage = buildSOAPMessage("removeOperation", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getOperationByID = function(operationID, callbackHandler) {
    var parameterNames = ["operationID"];
    var parameterValues = [operationID];
    var soapMessage = buildSOAPMessage("getOperationByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationTemplateID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getOperationTemplateByID = function(operationTemplateID, callbackHandler) {
    var parameterNames = ["operationTemplateID"];
    var parameterValues = [operationTemplateID];
    var soapMessage = buildSOAPMessage("getOperationTemplateByID", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = true;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param template [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.createOperationsByTemplate = function(template, callbackHandler) {
    var parameterNames = ["template"];
    var templateJSON = JSON.stringify(template);
    var parameterValues = [templateJSON];
    var soapMessage = buildSOAPMessage("createOperationsByTemplate", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param template [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.changeOperationTemplateParameters = function(template, callbackHandler) {
    var parameterNames = ["template"];
    var templateJSON = JSON.stringify(template);
    var parameterValues = [templateJSON];
    var soapMessage = buildSOAPMessage("changeOperationTemplateParameters", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param template [DEFAULT!!DEFAULT]
   * @param newSchedule [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.changeOperationSchedule = function(template, newSchedule, callbackHandler) {
    var parameterNames = ["template", "newSchedule"];
    var templateJSON = JSON.stringify(template);
    var newScheduleJSON = JSON.stringify(newSchedule);
    var parameterValues = [templateJSON, newScheduleJSON];
    var soapMessage = buildSOAPMessage("changeOperationSchedule", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getOperationsList = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getOperationsList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getDirectionDynamics = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getDirectionDynamics", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getCounterpartyDynamics = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getCounterpartyDynamics", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getAccountsChartDynamics = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getAccountsChartDynamics", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getProjectDynamics = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getProjectDynamics", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param cashflowItemID [DEFAULT!!DEFAULT]
   * @param plOperationsIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.setCashflowItemToOperations = function(cashflowItemID, plOperationsIDs, callbackHandler) {
    var parameterNames = ["cashflowItemID", "plOperationsIDs"];
    var plOperationsIDsJSON = JSON.stringify(plOperationsIDs);
    var parameterValues = [cashflowItemID, plOperationsIDsJSON];
    var soapMessage = buildSOAPMessage("setCashflowItemToOperations", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param projectID [DEFAULT!!DEFAULT]
   * @param plOperationsIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.setProjectToOperations = function(projectID, plOperationsIDs, callbackHandler) {
    var parameterNames = ["projectID", "plOperationsIDs"];
    var plOperationsIDsJSON = JSON.stringify(plOperationsIDs);
    var parameterValues = [projectID, plOperationsIDsJSON];
    var soapMessage = buildSOAPMessage("setProjectToOperations", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getSplitOperations = function(operationID, callbackHandler) {
    var parameterNames = ["operationID"];
    var parameterValues = [operationID];
    var soapMessage = buildSOAPMessage("getSplitOperations", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param sourcePayment [DEFAULT!!DEFAULT]
   * @param targetOperations [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.splitOperation = function(sourcePayment, targetOperations, callbackHandler) {
    var parameterNames = ["sourcePayment", "targetOperations"];
    var sourcePaymentJSON = JSON.stringify(sourcePayment);
    var targetOperationsJSON = JSON.stringify(targetOperations);
    var parameterValues = [sourcePaymentJSON, targetOperationsJSON];
    var soapMessage = buildSOAPMessage("splitOperation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param changeSet [DEFAULT!!DEFAULT]
   * @param sourcePayment [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.updateSplitOperations = function(changeSet, sourcePayment, callbackHandler) {
    var parameterNames = ["changeSet", "sourcePayment"];
    var changeSetJSON = JSON.stringify(changeSet);
    var sourcePaymentJSON = JSON.stringify(sourcePayment);
    var parameterValues = [changeSetJSON, sourcePaymentJSON];
    var soapMessage = buildSOAPMessage("updateSplitOperations", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param idList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getOperationsByIDList = function(idList, callbackHandler) {
    var parameterNames = ["idList"];
    var idListJSON = JSON.stringify(idList);
    var parameterValues = [idListJSON];
    var soapMessage = buildSOAPMessage("getOperationsByIDList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationsIDs [DEFAULT!!DEFAULT]
   * @param props [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.massUpdateOperations = function(operationsIDs, props, callbackHandler) {
    var parameterNames = ["operationsIDs", "props"];
    var operationsIDsJSON = JSON.stringify(operationsIDs);
    var propsJSON = JSON.stringify(props);
    var parameterValues = [operationsIDsJSON, propsJSON];
    var soapMessage = buildSOAPMessage("massUpdateOperations", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getCounterpartiesTop = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getCounterpartiesTop", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operations [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.transformPlanOperationsToFact = function(operations, callbackHandler) {
    var parameterNames = ["operations"];
    var operationsJSON = JSON.stringify(operations);
    var parameterValues = [operationsJSON];
    var soapMessage = buildSOAPMessage("transformPlanOperationsToFact", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!на выходе список ID операций со статусами отправки!DEFAULT]
   * @param plOperationIDs [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.sendPaymentsDraftsToCMS = function(plOperationIDs, callbackHandler) {
    var parameterNames = ["plOperationIDs"];
    var plOperationIDsJSON = JSON.stringify(plOperationIDs);
    var parameterValues = [plOperationIDsJSON];
    var soapMessage = buildSOAPMessage("sendPaymentsDraftsToCMS", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operation [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.addFactOperation = function(operation, callbackHandler) {
    var parameterNames = ["operation"];
    var operationJSON = JSON.stringify(operation);
    var parameterValues = [operationJSON];
    var soapMessage = buildSOAPMessage("addFactOperation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operation [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.updateFactOperation = function(operation, callbackHandler) {
    var parameterNames = ["operation"];
    var operationJSON = JSON.stringify(operation);
    var parameterValues = [operationJSON];
    var soapMessage = buildSOAPMessage("updateFactOperation", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param operationID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.removeFactOperation = function(operationID, callbackHandler) {
    var parameterNames = ["operationID"];
    var parameterValues = [operationID];
    var soapMessage = buildSOAPMessage("removeFactOperation", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.createPlanningPLOperations = function(callbackHandler) {
    var parameterNames = [];
    var parameterValues = [];
    var soapMessage = buildSOAPMessage("createPlanningPLOperations", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getPlanningReportData = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getPlanningReportData", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!количество операций по собственной организации!DEFAULT]
   * @param tenantLegalEntityID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getPLOperationsCount = function(tenantLegalEntityID, callbackHandler) {
    var parameterNames = ["tenantLegalEntityID"];
    var parameterValues = [tenantLegalEntityID];
    var soapMessage = buildSOAPMessage("getPLOperationsCount", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getOperationsBriefsList = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getOperationsBriefsList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param idList [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getOperationsBriefsByIDList = function(idList, callbackHandler) {
    var parameterNames = ["idList"];
    var idListJSON = JSON.stringify(idList);
    var parameterValues = [idListJSON];
    var soapMessage = buildSOAPMessage("getOperationsBriefsByIDList", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getBusinessUnitDynamics = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getBusinessUnitDynamics", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param plOperationID [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.removePlannedOperationsSeriesByOperationID = function(plOperationID, callbackHandler) {
    var parameterNames = ["plOperationID"];
    var parameterValues = [plOperationID];
    var soapMessage = buildSOAPMessage("removePlannedOperationsSeriesByOperationID", parameterNames, parameterValues);
    var resultHasComplexType = false;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  /***
   * [DEFAULT!!DEFAULT]
   * @param request [DEFAULT!!DEFAULT]
   * @param callbackHandler Function to service callback result
   */
  w.PLOperationServiceClient.prototype.getPLOperationACIRoleDynamics = function(request, callbackHandler) {
    var parameterNames = ["request"];
    var requestJSON = JSON.stringify(request);
    var parameterValues = [requestJSON];
    var soapMessage = buildSOAPMessage("getPLOperationACIRoleDynamics", parameterNames, parameterValues);
    var resultHasComplexType = true;
    var autoRestartOnError = false;
    callWebService(this.serviceName, soapMessage,resultHasComplexType, callbackHandler,'snc-core-services',autoRestartOnError);
  };
  
  
  /***
   * Package security rights constants
   */
  
  w.PlSecurityRights = {
  
      DASHBOARD_VIEW_RIGHT:new SecurityRightCheckHandle('aa445141-772c-4b56-8a03-1069c258470e'),
      PLOPERATION_INPUT_RIGHT:new SecurityRightCheckHandle('975cad7f-38a8-4385-9863-4b017695c7f9'),
      PLOPERATION_PLANNING_RIGHT:new SecurityRightCheckHandle('6411f26d-2380-4810-9b45-402e7d1adae2'),
      PLOPERATION_VIEW_RIGHT:new SecurityRightCheckHandle('5fbc494c-823f-47cf-850b-f9f6649c4107'),
      X_DASHBOARD__VIEW_BALANCES:new SecurityRightCheckHandle('d6f23de1-1c86-44ca-b762-444776f2e19a'),
      X_DASHBOARD__VIEW_DASHBOARD:new SecurityRightCheckHandle('2f301736-8358-4fea-bb24-5fce443fb2f2'),
      X_DASHBOARD__VIEW_FORECAST:new SecurityRightCheckHandle('98a2f046-96bd-426f-931a-7b6b588c09e9'),
      X_PLANNING__VIEW_PLANNING:new SecurityRightCheckHandle('73d985f7-be76-4acd-86a3-15c87230521e'),
      X_PLOPERATIONS__CHANGE_OPERATIONS:new SecurityRightCheckHandle('7c1f4268-7998-4e5a-8cb5-7ed82122b804'),
      X_PLOPERATIONS__VIEW_ANALYTICS:new SecurityRightCheckHandle('26e4fe61-1088-4d7e-a473-6119d87df272'),
      X_PLOPERATIONS__VIEW_OPERATIONS:new SecurityRightCheckHandle('1eb071af-887a-42b0-94dd-654d8cc799fe')
  };
  })(typeof exports !== 'undefined' ? exports : window);
  