import { createAsyncSelector, createSelector } from '@core/flux';
import { IAppState } from '@store';

const selectAsyncSessionVariables = createAsyncSelector<Array<SessionVariable>, IAppState>({
	get: s => s.platform.sessionVariables.sessionVariables,
	selector: createSelector(
		s => s.platform.sessionVariables.sessionVariables.item,
		item => item,
	),
});

export const mainSessionVariablesSelectorsPack = {
	selectAsyncSessionVariables,
};
