import { StoreAsyncItem, checkAsyncAction, createAsyncInitialState, createReducer } from '@flux';
import { mainSessionVariablesActionsPack } from '@platform/actions/session-variables.actions';

export type SessionVariablesState = {
	sessionVariables: StoreAsyncItem<Array<SessionVariable>>;
};

const initialState = {
	sessionVariables: createAsyncInitialState(null),
};

const mainSessionVariablesReducer = createReducer<SessionVariablesState>(initialState, {
	[mainSessionVariablesActionsPack.types.FETCH_SESSION_VARIABLES]: (
		action: AsyncAction<Array<SessionVariable>>,
		state,
	) => {
		return {
			sessionVariables: checkAsyncAction(action, state.sessionVariables),
		};
	},
});

export default mainSessionVariablesReducer;
