import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as moment from 'moment';

import api from '@api';
import { replaceMaskedValue } from '@utils/strings';
import { CMSDataType, DrawerZone } from '@funds-registers/models';
import { DrawerFooterControls, DrawerTitle } from '@ui/drawer';
import { ProviderIcon } from '@ui/provider-icon';
import { getDrawer } from '@utils';
import { AutopickerOnChangeOptions } from '@ui/autopicker';
import {
	selectSelectedCMS,
	selectSelectedExternalSystemAccount,
	selectSelectedExternalSystemAccountID,
} from '@integrations/selectors';
import { SelectDate } from '@funds-registers/components/cms-import-period-select';
import { Form, FormContent, FormInputField as FormTextField } from '@ui/forms';
import { Alert } from '@ui/alert';
import { Checkbox } from '@ui/checkbox';
import { Box } from '@ui/box';
import { FormFundsRegisterTextField } from '@ui/funds-register-text-field';
import {
	FormTenantLegalEntityAutopicker,
	tenantLegalEntityAutopickerTransformer,
} from '@tenant-legal-entities/components/tenant-legal-entity-autopicker';
import { selectFundsRegisterForAutoInsert } from '@funds-registers/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import { ImportCmsAccountList } from '@funds-registers/components/import-cms-account-list';
import { FormTaxCodeTextField } from '@counterparties/components/tax-code-text-field';

export type XCmsImportProps = {
	selectedCMS: ReturnType<typeof selectSelectedCMS>;
	selectedExternalSystemAccountID: ReturnType<typeof selectSelectedExternalSystemAccountID>;
	selectedExternalSystemAccount: ReturnType<typeof selectSelectedExternalSystemAccount>;
	dateRange: DateRange;
	dataType: CMSDataType;
	autoInsertFundsRegister: ReturnType<typeof selectFundsRegisterForAutoInsert>;
	status: AsyncProgressStatus;
	isFetchingCmsAccounts: boolean;
	cmsAccounts: CMSAccount[];
	currenciesMap: Record<string, Currency>;
	onStartImportAccountFromCMS: (cmsAccount: CMSAccount, dateRange: DateRange) => void;
	setLastImportedAccountData: typeof mainIntegrationsActionsPack.setLastImportedAccountData;
	onSetZone: React.Dispatch<React.SetStateAction<DrawerZone>>;
	onFetchCmsAccounts: (
		dataType: CMSDataType,
		legalEntityName?: string,
		legalEntityTaxCode?: string,
		legalEntityUID?: string,
	) => Promise<CMSAccountsResponse>;
};

const XCmsImport: React.FC<XCmsImportProps> = props => {
	const {
		selectedCMS,
		selectedExternalSystemAccountID,
		selectedExternalSystemAccount,
		dateRange,
		dataType,
		autoInsertFundsRegister,
		status,
		isFetchingCmsAccounts,
		cmsAccounts,
		currenciesMap,
		onStartImportAccountFromCMS,
		setLastImportedAccountData,
		onSetZone,
		onFetchCmsAccounts,
	} = props;

	useLayoutEffect(() => {
		getDrawer().setContent({
			title: (
				<DrawerTitle
					title={selectedCMS.Name}
					linkLabel='Назад'
					onNavigate={handleBack}
					icon={<ProviderIcon providerName={selectedCMS.Name} size={30} fallback='bank-account' />}
				/>
			),
		});
	}, []);

	const handleBack = () => onSetZone(DrawerZone.ACOUNTS);
	const handleClose = () => getDrawer().close();

	const isPosTerminal = selectedCMS.ProvidedData.indexOf(CMSDataType.POS_TERMINAL);
	if (!selectedCMS.SupportGetAccountList && !isPosTerminal) {
		return (
			<DefaultForm
				selectedCMS={selectedCMS}
				selectedExternalSystemAccountID={selectedExternalSystemAccountID}
				dateRange={dateRange}
				currenciesMap={currenciesMap}
				setLastImportedAccountData={setLastImportedAccountData}
				autoInsertFundsRegister={autoInsertFundsRegister}
				handleClose={handleClose}
				onStartImportAccountFromCMS={onStartImportAccountFromCMS}
			/>
		);
	}
	if (!selectedCMS.RequestLegalEntityToGetAccountsList || selectedExternalSystemAccount.TaxCode || isPosTerminal) {
		return (
			<SupportAccountForm
				selectedCMS={selectedCMS}
				dateRange={dateRange}
				dataType={dataType}
				status={status}
				isFetchingCmsAccounts={isFetchingCmsAccounts}
				cmsAccounts={cmsAccounts}
				legalEntityName={selectedExternalSystemAccount.Name}
				legalEntityTaxCode={selectedExternalSystemAccount.TaxCode}
				onStartImportAccountFromCMS={onStartImportAccountFromCMS}
				onFetchCmsAccounts={onFetchCmsAccounts}
			/>
		);
	}
	return (
		<LegalEntityRequestForm
			dateRange={dateRange}
			status={status}
			isFetchingCmsAccounts={isFetchingCmsAccounts}
			cmsAccounts={cmsAccounts}
			autoInsertFundsRegister={autoInsertFundsRegister}
			handleClose={handleClose}
			onFetchCmsAccounts={onFetchCmsAccounts}
			onStartImportAccountFromCMS={onStartImportAccountFromCMS}
		/>
	);
};

type DefaultFormProps = {
	selectedCMS: ReturnType<typeof selectSelectedCMS>;
	selectedExternalSystemAccountID: ReturnType<typeof selectSelectedExternalSystemAccountID>;
	dateRange: DateRange;
	currenciesMap: Record<string, Currency>;
	autoInsertFundsRegister: ReturnType<typeof selectFundsRegisterForAutoInsert>;
	onStartImportAccountFromCMS: (cmsAccount: CMSAccount, dateRange: DateRange, dataType?: CMSDataType) => void;
	setLastImportedAccountData: typeof mainIntegrationsActionsPack.setLastImportedAccountData;
	handleClose: () => void;
};

type DefaultFormObject = {
	registerNumber: string;
	registerName: string;
	currency: Currency;
	legalEntity: CounterpartyBrief;
};

const DefaultForm: React.FC<DefaultFormProps> = props => {
	const {
		selectedCMS,
		selectedExternalSystemAccountID,
		dateRange,
		currenciesMap,
		autoInsertFundsRegister,
		onStartImportAccountFromCMS,
		setLastImportedAccountData,
		handleClose,
	} = props;
	const initFormObject = {
		registerNumber: autoInsertFundsRegister?.RegisterNumber || '',
		registerName: autoInsertFundsRegister?.Name || '',
		currency: currenciesMap[autoInsertFundsRegister?.CurrencyID] || null,
		legalEntity: autoInsertFundsRegister?.LegalEntity || null,
	};
	const formRef = useRef<Form<DefaultFormObject>>();

	useLayoutEffect(() => {
		getDrawer().setContent({
			footer: <DrawerFooterControls actionLabel='Импорт' onAction={handleInitSubmit} onClose={handleClose} />,
		});
	}, []);

	const handleInitSubmit = () => formRef.current.submitForm();

	const handleSubmit = (formObject: DefaultFormObject) => {
		const registerNumber = replaceMaskedValue(formObject.registerNumber);
		const cmsAccount = {
			...new api.fundspackage.CMSAccount(),
			Status: 1,
			AccountNumber: registerNumber,
			AccountName: formObject.registerName,
			AccountCurrencyLetterCode: formObject.currency.LetterCode,
			LegalEntityName: formObject?.legalEntity?.Name || '',
			LegalEntityUID: formObject?.legalEntity?.GUID || '',
			ExternalSystemAccountID: selectedExternalSystemAccountID,
		};

		onStartImportAccountFromCMS(cmsAccount, dateRange);
		setLastImportedAccountData({
			registerNumber,
			legalEntityName: formObject?.legalEntity?.Name || '',
		});
	};

	return (
		<>
			<SelectDate />
			<FormContent>
				<Alert appearance='warning' fullWidth>
					{selectedCMS.Name} не предоставляет данные о ваших счетах. Укажите реквизиты счёта и нажмите кнопку «Импорт».
				</Alert>
				<Form ref={formRef} formObject={initFormObject} fullWidth onSubmit={handleSubmit}>
					<FormFundsRegisterTextField
						name='registerNumber'
						appearance='bank-account'
						labelText='Номер счёта'
						enableOnBlurValidation
						withClearBtn
						required
						fullWidth
						getCurrencyID={getCurrencyID}
						setCurrency={setCurrency}
					/>
					<FormTextField
						name='Name'
						briefObjectName='currency'
						labelText='Валюта счёта'
						hintText='Определяется автоматически'
						disabled
						fullWidth
						required
					/>
					<FormTextField
						name='registerName'
						labelText='Наименование счёта (необязательно)'
						hintText='Например, «Мой счёт»'
						withClearBtn
						fullWidth
					/>
					<FormTenantLegalEntityAutopicker
						name='legalEntity'
						labelText='Организация-владелец счёта (необязательно)'
						hintText='Например, «Ромашка»'
						disableAdd
						disableEdit
						fullWidth
						{...tenantLegalEntityAutopickerTransformer.counterpartyBrief.single}
					/>
				</Form>
			</FormContent>
		</>
	);
};

type SupportAccountFormProps = {
	selectedCMS;
	dateRange: DateRange;
	dataType: CMSDataType;
	status: AsyncProgressStatus;
	isFetchingCmsAccounts: boolean;
	cmsAccounts: CMSAccount[];
	legalEntityName: string;
	legalEntityTaxCode: string;
	onStartImportAccountFromCMS: (cmsAccount: CMSAccount, dateRange: DateRange, dataType: CMSDataType) => void;
	onFetchCmsAccounts: (
		dataType: CMSDataType,
		legalEntityName?: string,
		legalEntityTaxCode?: string,
		legalEntityUID?: string,
	) => Promise<CMSAccountsResponse>;
};

const SupportAccountForm: React.FC<SupportAccountFormProps> = props => {
	const {
		dateRange,
		dataType,
		status,
		isFetchingCmsAccounts,
		cmsAccounts,
		legalEntityName,
		legalEntityTaxCode,
		onStartImportAccountFromCMS,
		onFetchCmsAccounts,
	} = props;
	useLayoutEffect(() => {
		getDrawer().setContent({
			footer: null,
		});
	}, []);
	useEffect(() => {
		onFetchCmsAccounts(dataType, legalEntityName, legalEntityTaxCode, undefined);
	}, []);

	const handleStartImport = (register: CMSAccount) => {
		const { dateStart, dateEnd } = dateRange;
		let dateStartModified = dateStart;
		if (register.OpenDate) {
			const dateStartMoment = moment(dateStart, 'DD-MM-YYYY');
			const accountDateOpenedMoment = moment(register.OpenDate, 'DD-MM-YYYY');

			if (dateStartMoment.isBefore(accountDateOpenedMoment)) {
				dateStartModified = register.OpenDate;
			}
		}

		onStartImportAccountFromCMS(register, { dateStart: dateStartModified, dateEnd }, dataType);
	};

	return (
		<ImportCmsAccountList
			isFetching={isFetchingCmsAccounts}
			status={status}
			cmsAccounts={cmsAccounts}
			onStartImport={handleStartImport}
		/>
	);
};

type LegalEntityRequestFormObject = {
	legalEntity: CounterpartyBrief;
	legalEntityName: string;
	legalEntityTaxCode: string;
};

type LegalEntityRequestProps = {
	dateRange: DateRange;
	status: AsyncProgressStatus;
	isFetchingCmsAccounts: boolean;
	cmsAccounts: CMSAccount[];
	autoInsertFundsRegister: ReturnType<typeof selectFundsRegisterForAutoInsert>;
	handleClose: () => void;
	onFetchCmsAccounts: (
		legalEntityName?: string,
		legalEntityTaxCode?: string,
		legalEntityUID?: string,
	) => Promise<CMSAccountsResponse>;
	onStartImportAccountFromCMS?: (cmsAccount: CMSAccount, dateRange: DateRange) => void;
};

const LegalEntityRequestForm: React.FC<LegalEntityRequestProps> = props => {
	const {
		dateRange,
		status,
		isFetchingCmsAccounts,
		cmsAccounts,
		autoInsertFundsRegister,
		handleClose,
		onFetchCmsAccounts,
		onStartImportAccountFromCMS,
	} = props;
	const initFormObject = {
		legalEntity: autoInsertFundsRegister?.LegalEntity || null,
		legalEntityName: autoInsertFundsRegister?.LegalEntity?.Name || '',
		legalEntityTaxCode: autoInsertFundsRegister?.LegalEntity?.TaxCode || '',
	};
	const [isManualEnter, setIsManualEnter] = useState(false);
	const [showRegisters, setShowRegisters] = useState(false);

	const formRef = useRef<Form<LegalEntityRequestFormObject>>();

	useLayoutEffect(() => {
		getDrawer().setContent({
			footer: <DrawerFooterControls actionLabel='Получить счета' onAction={handleInitSubmit} onClose={handleClose} />,
		});
	}, []);

	const handleStartImport = (register: CMSAccount) => {
		const { dateStart, dateEnd } = dateRange;
		let dateStartModified = dateStart;

		if (register.OpenDate) {
			const dateStartMoment = moment(dateStart, 'DD-MM-YYYY');
			const accountDateOpenedMoment = moment(register.OpenDate, 'DD-MM-YYYY');

			if (dateStartMoment.isBefore(accountDateOpenedMoment)) {
				dateStartModified = register.OpenDate;
			}
		}

		onStartImportAccountFromCMS(register, { dateStart: dateStartModified, dateEnd });
	};

	const handleInitSubmit = () => formRef.current.submitForm();

	const handleSubmit = (formObject: LegalEntityRequestFormObject) => {
		const legalEntityName = formObject.legalEntityName;
		const legalEntityTaxCode = formObject.legalEntityTaxCode;
		const legalEntityUID = formObject.legalEntity ? formObject.legalEntity.GUID || '' : '';

		onFetchCmsAccounts(legalEntityName, legalEntityTaxCode, legalEntityUID);

		setShowRegisters(true);
	};

	const handleChangeLegalEntity = ({ item }: AutopickerOnChangeOptions<CounterpartyBrief>) => {
		if (formRef) {
			const formObject = formRef.current.getFormObject();

			formObject.legalEntityName = item ? item.Name || item.NameEng : '';
			formObject.legalEntityTaxCode = item?.TaxCode || '';

			formRef.current.handleObjectChange(formObject);
		}
	};

	const handleCheckManualEnter = () => {
		setIsManualEnter(!isManualEnter);
	};

	if (showRegisters) {
		return (
			<ImportCmsAccountList
				isFetching={isFetchingCmsAccounts}
				status={status}
				cmsAccounts={cmsAccounts}
				onStartImport={handleStartImport}
			/>
		);
	}

	return (
		<FormContent>
			<Form ref={formRef} formObject={initFormObject} fullWidth onSubmit={handleSubmit}>
				<FormTenantLegalEntityAutopicker
					name='legalEntity'
					labelText='Организация-владелец счёта'
					hintText='Например, «Ромашка»'
					disableAdd
					disableEdit
					fullWidth
					onChange={handleChangeLegalEntity}
					{...tenantLegalEntityAutopickerTransformer.counterpartyBrief.single}
				/>
				<Box height={12} />
				<Checkbox label='Указать реквизиты вручную' checked={isManualEnter} onCheck={handleCheckManualEnter} />
				<FormTextField
					name='legalEntityName'
					labelText='Наименование организации (необязательно)'
					hintText='Например, «Ромашка»'
					withClearBtn
					fullWidth
					disabled={!isManualEnter}
				/>
				<FormTaxCodeTextField
					name='legalEntityTaxCode'
					variant='all'
					disabled={!isManualEnter}
					notSetReadonlyIfFieldComplete
					required
					fullWidth
				/>
			</Form>
		</FormContent>
	);
};

const getCurrencyID = (formObject: DefaultFormObject) => formObject?.currency?.ID || -1;
const setCurrency = (formObject: DefaultFormObject, currency: Currency) => (formObject.currency = currency || null);

export { XCmsImport };
