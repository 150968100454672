import React, { memo } from 'react';

import { useMapDispatch, useMapState } from '@core/flux';
import { mainFundsRegistersSelectorsPack } from '@funds-registers/selectors';
import { mainPaymentsActionsPack } from '@payments/actions';
import { XImportCmsAccountList, XImportCmsAccountListProps } from './import-cms-account-list.view';

type ImportCmsAccountListProps = Pick<
	XImportCmsAccountListProps,
	'isFetching' | 'status' | 'cmsAccounts' | 'onStartImport'
>;

const ImportCmsAccountList: React.FC<ImportCmsAccountListProps> = memo(props => {
	const { isFetching, status, cmsAccounts, onStartImport } = props;
	const [fundsRegisterStatisticsMap] = useMapState([mainFundsRegistersSelectorsPack.selectFundsRegisterStatisticsMap]);
	const [setFundsRegisterID] = useMapDispatch([mainPaymentsActionsPack.setFundsRegisterID]);

	return (
		<XImportCmsAccountList
			isFetching={isFetching}
			status={status}
			cmsAccounts={cmsAccounts}
			fundsRegisterStatisticsMap={fundsRegisterStatisticsMap}
			setFundsRegisterID={setFundsRegisterID}
			onStartImport={onStartImport}
		/>
	);
});

export { ImportCmsAccountList };
