import React from 'react';

import avangard from '@assets/img/logos/providers/avangard.png';
import sberbank from '@assets/img/logos/providers/sberbank.svg';
import binbank from '@assets/img/logos/providers/binbank.png';
import gazprombank from '@assets/img/logos/providers/gazprobank.png';
import promsvyazbank from '@assets/img/logos/providers/promsvyazbank.png';
import raiffeisen from '@assets/img/logos/providers/raiffeisen.png';
import smpbank from '@assets/img/logos/providers/smpbank.png';
import tochka from '@assets/img/logos/providers/tochka.png';
import ubrir from '@assets/img/logos/providers/ubrir.png';
import uralsib from '@assets/img/logos/providers/uralsib.png';
import openbank from '@assets/img/logos/providers/openbank.svg';
import modulebank from '@assets/img/logos/providers/modulbank.svg';
import vtb from '@assets/img/logos/providers/vtb.svg';
import alfabank from '@assets/img/logos/providers/alfabank.svg';
import tinkoff from '@assets/img/logos/providers/tinkoff.svg';
import sovcom from '@assets/img/logos/providers/sovcom.png';
import rosselhoz from '@assets/img/logos/providers/rosselhoz.svg';
import facebook from '@assets/img/logos/providers/facebook.svg';
import vk from '@assets/img/logos/providers/vk.svg';
import google from '@assets/img/logos/providers/google.svg';
import ioKassa from '@assets/img/logos/providers/io-kassa.svg';
import zenMoney from '@assets/img/logos/providers/zen-money.png';
import yandex from '@assets/img/logos/providers/yandex.png';
import amoCRM from '@assets/img/logos/providers/amocrm.png';
import ozon from '@assets/img/logos/providers/ozon.png';
import wildberries from '@assets/img/logos/providers/wildberries.png';

import { BankIcon } from '@ui/icons/bank';
import { WalletIcon } from '@ui/icons/wallet';
import { Root } from './styled';

export type ProviderIconProps = {
	providerName: string;
	size: number;
	fallback?: 'bank-account' | 'virtual-account';
};

class ProviderIcon extends React.PureComponent<ProviderIconProps> {
	static displayName = 'ProviderIcon';
	static defaultProps = {
		fallback: 'bank-account',
	};

	render() {
		const { providerName, size, fallback } = this.props;
		const resourceUrl = getProvideResourceUrl(providerName);

		if (!resourceUrl) return getFallback(fallback, size) || null;
		return <Root size={size} resourceUrl={resourceUrl} />;
	}
}

function getProvideResourceUrl(name: string) {
	if (/(АЛЬФА-БАНК)|(АЛЬФАБАНК)/gi.test(name)) return alfabank;
	if (/АВАНГАРД/gi.test(name)) return avangard;
	if (/БИНБАНК/gi.test(name)) return binbank;
	if (/ГПБ/gi.test(name)) return gazprombank;
	if (/МОДУЛЬБАНК/gi.test(name)) return modulebank;
	if (/ТОЧКА/gi.test(name)) return tochka;
	if (/ОТКРЫТИЕ/gi.test(name)) return openbank;
	if (/ПРОМСВЯЗЬБАНК/gi.test(name)) return promsvyazbank;
	if (/РАЙФФАЙЗЕНБАНК/gi.test(name)) return raiffeisen;
	if (/СБЕРБАНК/gi.test(name)) return sberbank;
	if (/СМП БАНК/gi.test(name)) return smpbank;
	if (/ТИНЬКОФФ БАНК/gi.test(name)) return tinkoff;
	if (/УБРИР/gi.test(name)) return ubrir;
	if (/УРАЛСИБ/gi.test(name)) return uralsib;
	if (/ВТБ/gi.test(name)) return vtb;
	if (/(РОССЕЛЬХОЗБАНК)|(РСХБ)/gi.test(name)) return rosselhoz;
	if (/СОВКОМБАНК/gi.test(name)) return sovcom;
	if (/FACEBOOK/gi.test(name)) return facebook;
	if (/(VK)|(VKONTAKTE)|(ВКОНТАКТЕ)/gi.test(name)) return vk;
	if (/GOOGLE/gi.test(name)) return google;
	if (/(ЮKassa)|(ЮКасса)/gi.test(name)) return ioKassa;
	if (/Zen money/gi.test(name)) return zenMoney;
	if (/(Yandex)|(Яндекс)/gi.test(name)) return yandex;
	if (/(АМО CRM)/gi.test(name)) return amoCRM;
	if (/(Ozon)/gi.test(name)) return ozon;
	if (/(Wildberries)/gi.test(name)) return wildberries;

	return null;
}

function getFallback(fallback: ProviderIconProps['fallback'], size: number) {
	const map = {
		'bank-account': () => <BankIcon color='black' size={size} />,
		'virtual-account': () => <WalletIcon color='black' size={size} />,
	};

	return map[fallback] ? map[fallback]() : null;
}

function detectIsMatchAnyProvider(name: string) {
	return Boolean(getProvideResourceUrl(name));
}

export { ProviderIcon, detectIsMatchAnyProvider };
