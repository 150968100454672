import { combineReducers } from 'redux';

import authReducer, { IAuthState } from './reducers/auth.reducer';
import billingReducer, { IBillingState } from './reducers/billing.reducers';
import displayControllerReducer, { IDisplayControllerState } from './reducers/display-controller.reducers';
import externalSystemAccountReducer, { IExternalSystemAccountState } from './reducers/external-system-account.reducers';
import tenantProfileReducer, { TenantProfileState } from './reducers/main-tenant-profile.reducers';
import contextReducer from './reducers/session-context.reducers';
import mainSessionVariablesReducer, { SessionVariablesState } from './reducers/session-variables.reducers';
import tenantAccountReducer, { ITenantAccountState } from './reducers/tenant-account.reducers';

export const tenantAccountLocalRoutes = {
	billsAndServices: 'bills-and-services',
	contactData: 'contact-data',
	dataDeleting: 'data-deleting',
	developerZone: 'developer-zone',
	disableService: 'disable-service',
	integrations: 'integrations',
	payeeRequisites: 'payee-requisites',
	settings: 'settings',
};

export interface IPlatformState {
	auth: IAuthState;
	billing: IBillingState;
	displayController: IDisplayControllerState;
	externalSystemAccount: IExternalSystemAccountState;
	sessionContext: StoreAsyncItem<ClientSessionContext>;
	sessionVariables: SessionVariablesState;
	tenantAccount: ITenantAccountState;
	tenantProfile: TenantProfileState;
}

const platformReducer = combineReducers<IPlatformState>({
	auth: authReducer,
	billing: billingReducer,
	displayController: displayControllerReducer,
	externalSystemAccount: externalSystemAccountReducer,
	sessionContext: contextReducer,
	sessionVariables: mainSessionVariablesReducer,
	tenantAccount: tenantAccountReducer,
	tenantProfile: tenantProfileReducer,
});

export { platformReducer };
