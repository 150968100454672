import * as moment from 'moment';
import React from 'react';

import { getGlobalConfig } from '@config';
import { carrotquestApi } from '@core/api/carrotquest';
import { BusinessPlusTariffIllustration } from '@core/ui/illustrations/business-plus-tariff';
import { BusinessTariffIllustration } from '@core/ui/illustrations/business-tariff';
import { SoleProprietorTariffIllustration } from '@core/ui/illustrations/sole-proprietor-tariff';
import { StartupTariffIllustration } from '@core/ui/illustrations/startup-tariff';
import { BillingProvider } from '@platform/model/billing';
import { Alert } from '@ui/alert';
import { CardPicker, CardPickerSourceItem } from '@ui/card-picker';
import { DrawerRightControls } from '@ui/drawer';
import { FormGroup, FormGroupTitle } from '@ui/forms';
import { Link } from '@ui/link';
import { Loader } from '@ui/loader';
import { RaisedButton } from '@ui/raised-button';
import { ISwitchPickerSourceItem, SwitchPicker } from '@ui/switch-picker';
import { FlatTab, FlatTabs } from '@ui/tabs';
import { formatCurrency, getDrawer, getPropInSafe } from '@utils';
import { pathTo } from '../../billing-display-controller/paths';
import BillingPreview from '../../billing-preview';
import FastBillForm from '../../forms/fast-bill-form';
import {
	BillingPreviewLayout,
	buttonStyle,
	ChangeLinkLayout,
	ContentLayout,
	LoaderLayout,
	MessageCardLayout,
	Param,
	ParamSign,
	ParamsLayout,
	ParamValue,
	PayButtonLayout,
	PickerLayout,
	PreviewContentLayout,
	RatesContentLayout,
	RatesDates,
	RatesDesc,
	RatesName,
	RatesSum,
	RatesSumContainer,
	RatesTitle,
	Root,
	SyncPaymentLayout,
	TitleLayout,
} from './styled';

export type SelectConditionsDisplayProps = {
	tenantID: number;
	tariffList: Array<Tariff>;
	isFetching: boolean;
	selectedTariff: Tariff;
	selectedTariffRate: TariffRate;
	requestedTariff: Tariff;
	requestedTariffRate: TariffRate;
	activeTariff: Tariff;
	tenantBills: Record<string, TenantBill>;
	billingServiceStatus: BillingServiceStatus;
	tenantAccount: TenantAccount;
	tenant: Tenant;
	isSentToCMS: boolean;
	setPath: (path: string) => void;
	resetPath: () => void;
	selectTariff: (tariff: Tariff, tariffRate: TariffRate) => void;
	revokeTenantBill: (billID: number) => void;
	registerServiceRequest: (tenantID: number, tariffRateID: number, startDate: string) => void;
	createPaymentServiceRequest: (billID: number, paymentServiceCode: string) => void;
	resetStatusAfterBilling: () => void;
	runForceSyncPaymentStatusEffect: () => void;
};

type SelectConditionsDisplayState = {
	activeTariffIndex: number;
	activeRateIndex: number;
};

class SelectConditionsDisplay extends React.PureComponent<SelectConditionsDisplayProps, SelectConditionsDisplayState> {
	static displayName = 'SelectConditionsDisplay';
	state: SelectConditionsDisplayState = {
		activeTariffIndex: 0,
		activeRateIndex: 0,
	};
	getTariffIcons =
		(index: number) =>
		(color: 'accent' | 'muted' | 'black' | 'alarm' | 'warning' | 'light' | 'positive' | 'negative' | 'initial') => {
			const icons = [
				<SoleProprietorTariffIllustration size={40} color={color} />,
				<StartupTariffIllustration size={40} color={color} />,
				<BusinessTariffIllustration size={40} color={color} />,
				<BusinessPlusTariffIllustration size={40} color={color} />,
				<BusinessPlusTariffIllustration size={40} color={color} />,
			];
			return icons[index] || icons[icons.length - 1];
		};
	tariffSourceList: Array<CardPickerSourceItem<Tariff>> = [];
	nextButtonIsHidden = false;

	componentDidMount() {
		this.hideNextButton();
		this.setActiveIndexes();
	}

	componentDidUpdate(prevProps: SelectConditionsDisplayProps) {
		if (this.tariffSourceList.length === 0 && this.props.tariffList.length > 0) {
			this.buildTariffSource(this.props.tariffList);
		}

		if (this.props.tariffList.length === 0 && !this.nextButtonIsHidden) {
			this.hideNextButton();
		}

		if (this.props.tariffList.length > 0 && this.nextButtonIsHidden) {
			this.showNextButton();
		}
		if (this.isBillWaitingForPaymentConfirmation(this.props)) {
			setImmediate(() => {
				this.props.setPath(pathTo.legalEntityPaymentResult);
			});
		}
	}

	getIsUnpaidBillExists = (props: SelectConditionsDisplayProps = this.props) => {
		return props.tenantBills ? Object.keys(props.tenantBills).length > 0 : false;
	};

	showNextButton = () => {
		this.nextButtonIsHidden = false;

		getDrawer().setContent({
			rightContent: (
				<DrawerRightControls
					actionLabel='Далее'
					onAction={this.handleNextStep}
					onClose={this.handleClose}
					onSecondAction={this.handleOpenHelp}
					secondActionLabel='Помощь'
				/>
			),
		});
	};

	hideNextButton = () => {
		this.nextButtonIsHidden = true;

		getDrawer().setContent({
			rightContent: <DrawerRightControls actionLabel={'Закрыть'} onAction={this.handleClose} />,
		});
	};

	resetActiveIndexes = () => {
		this.setState({
			activeTariffIndex: 0,
			activeRateIndex: 0,
		});
	};

	setActiveIndexes = () => {
		const { tariffList, requestedTariff, selectedTariff, selectedTariffRate, activeTariff } = this.props;

		if (tariffList.length > 0) {
			if (activeTariff) {
				const tariffIdx = tariffList.findIndex(tariff => tariff.ID === activeTariff.ID);
				const activeTariffIndex = tariffIdx > 0 ? tariffIdx : 0;

				this.setState({ activeTariffIndex });
				return;
			}

			if (!requestedTariff && selectedTariff && selectedTariffRate) {
				const activeTariffIndex = tariffList.findIndex(tariff => tariff.ID === selectedTariff.ID);
				const activeRateIndex = tariffList[activeTariffIndex].Rates.findIndex(
					rate => rate.ID === selectedTariffRate.ID,
				);

				this.setState({
					activeTariffIndex,
					activeRateIndex,
				});
			}
		}
	};

	buildTariffSource = (tariffList: Array<Tariff>) => {
		this.tariffSourceList = tariffList.map((tariff, index) => ({
			value: index,
			text: tariff.Name,
			item: tariff,
			renderIcon: this.getTariffIcons(index),
		}));
	};

	initPaymentRequestForPerson = (ev, serviceCode: BillingProvider) => {
		const { tenantBills, createPaymentServiceRequest } = this.props;

		if (Object.keys(tenantBills).length > 0) {
			const tenantBillsList = Object.keys(tenantBills).map(key => tenantBills[key]);

			createPaymentServiceRequest(tenantBillsList[0].ID, serviceCode);
		}
	};

	handleNextStep = () => {
		const { tenantID, tenantAccount, selectTariff, registerServiceRequest, setPath } = this.props;
		const { activeTariffIndex, activeRateIndex } = this.state;
		const selectedTariff = this.tariffSourceList[activeTariffIndex].item;
		const selectedTariffRate = selectedTariff.Rates[activeRateIndex];
		const isUnpaidBillExists = this.getIsUnpaidBillExists();
		const startData = getStartMoment(tenantAccount).format('DD-MM-YYYY');

		selectTariff(selectedTariff, selectedTariffRate);
		!isUnpaidBillExists && registerServiceRequest(tenantID, selectedTariffRate.ID, startData);
		setPath(pathTo.selectTypePayer);
	};

	handleOpenHelp = () => {
		carrotquestApi.startConversation('Нужна помощь с выбором тарифа');
	};

	handleClose = () => {
		getDrawer().close();
	};

	handleSelectTariff = (source: CardPickerSourceItem<Tariff>) => {
		const { activeTariffIndex, activeRateIndex } = this.state;

		this.setState({
			activeTariffIndex: source.value,
			activeRateIndex: activeTariffIndex !== source.value ? 0 : activeRateIndex,
		});
	};

	handleSelectRate = (source: ISwitchPickerSourceItem<TariffRate>) => {
		this.setState({
			activeRateIndex: source.value,
		});
	};

	handleRevokeTenantBill = () => {
		const { tenantBills, revokeTenantBill, resetStatusAfterBilling } = this.props;

		Object.keys(tenantBills).forEach(key => {
			revokeTenantBill(tenantBills[key].ID);
		});

		resetStatusAfterBilling();
	};

	renderRatesCard = (rate: TariffRate) => {
		const { tenantAccount } = this.props;
		const dateStartMoment = getStartMoment(tenantAccount);
		const dateStart = dateStartMoment.format('DD.MM.YYYY');
		const dateEnd = dateStartMoment.clone().add(rate.Duration, 'month').format('DD.MM.YYYY');

		return (
			<RatesContentLayout>
				<RatesDesc>
					<RatesTitle>
						Продлить на <RatesName>{rate.Name}</RatesName>
					</RatesTitle>
					<RatesDates>{`C ${dateStart} по ${dateEnd}`}</RatesDates>
				</RatesDesc>
				<RatesSumContainer>
					<RatesSum flex>
						<RatesSum>{formatCurrency(rate.Rate)}</RatesSum>
					</RatesSum>
				</RatesSumContainer>
			</RatesContentLayout>
		);
	};

	renderRates = () => {
		const { activeTariffIndex, activeRateIndex } = this.state;
		const rates = getPropInSafe(this.tariffSourceList, o => o[activeTariffIndex].item.Rates, []);

		if (rates.length > 0) {
			return this.tariffSourceList.map((source, index) => {
				const ratesSourceList: Array<ISwitchPickerSourceItem<TariffRate>> = rates.map((rate, index) => {
					return {
						value: index,
						text: rate.Name,
						item: rate,
					};
				});

				ratesSourceList.sort((a, b) => (a.item.Duration > b.item.Duration ? 1 : -1));

				return (
					<FlatTab key={index}>
						<SwitchPicker
							dataSource={ratesSourceList}
							value={activeRateIndex}
							render={this.renderRatesCard}
							onChange={this.handleSelectRate}
						/>
					</FlatTab>
				);
			});
		}

		return null;
	};

	isBillSentToPaymentGateway = (props = this.props) => {
		const tenantBillsList = Object.keys(props.tenantBills).map(key => props.tenantBills[key]);
		const tenantBillStatus = tenantBillsList[0] && tenantBillsList[0].StatusCode;
		return tenantBillStatus === 'SENT_TO_PAYMENT_GATEWAY';
	};

	isBillWaitingForPaymentConfirmation = (props = this.props) => {
		const tenantBillsList = Object.keys(props.tenantBills).map(key => props.tenantBills[key]);
		const tenantBillStatus = tenantBillsList[0] && tenantBillsList[0].StatusCode;
		return tenantBillStatus === 'WAITING_FOR_PAYMENT_CONFIRMATION';
	};

	isBillPaid = (props = this.props) => {
		const tenantBillsList = Object.keys(props.tenantBills).map(key => props.tenantBills[key]);
		const tenantBillStatus = tenantBillsList[0] && tenantBillsList[0].StatusCode;
		return tenantBillStatus === 'PAID' || tenantBillsList.length === 0;
	};

	isBillInProgress = () => {
		return this.isBillSentToPaymentGateway() || this.isBillWaitingForPaymentConfirmation();
	};

	handleClickSyncPayment = () => {
		this.props.runForceSyncPaymentStatusEffect();
	};

	renderRequestedTariffBlock = () => {
		const { requestedTariff, requestedTariffRate, tenantBills, isSentToCMS, tenantAccount } = this.props;
		const isBillInProgress = this.isBillWaitingForPaymentConfirmation() || this.isBillSentToPaymentGateway();
		const tenantBillsList = Object.keys(tenantBills).map(key => tenantBills[key]);
		const dateStartMoment = moment(
			tenantBillsList[0]?.PeriodDateStart || getStartMoment(tenantAccount).format('DD-MM-YYYY'),
			'DD-MM-YYYY',
		);
		const dateEndMoment = dateStartMoment.clone().add(requestedTariffRate.Duration, 'month');
		const needRenderChangeBtn = !isBillInProgress && tenantBillsList.length > 0 && !isSentToCMS;

		return (
			<FormGroup>
				<FormGroupTitle fontSize={'14px'} lineHeight={1.43} marginBottom={'20px'}>
					Вами запрошена активация сервиса со следующими параметрами:
				</FormGroupTitle>
				<ParamsLayout>
					<Param>
						<ParamSign>Тариф</ParamSign>
						<ParamValue>{requestedTariff.Name}</ParamValue>
					</Param>
					<Param>
						<ParamSign>Период</ParamSign>
						<ParamValue>{`c ${dateStartMoment.format('DD.MM.YYYY')} по ${dateEndMoment.format(
							'DD.MM.YYYY',
						)}`}</ParamValue>
					</Param>
				</ParamsLayout>
				{needRenderChangeBtn && (
					<ChangeLinkLayout>
						<Link onClick={this.handleRevokeTenantBill}>Изменить тариф или период</Link>
					</ChangeLinkLayout>
				)}
			</FormGroup>
		);
	};

	renderContentIfUnpaidBillIsExist = () => {
		const { tenantBills } = this.props;
		const isBillInProgress = this.isBillWaitingForPaymentConfirmation() || this.isBillSentToPaymentGateway();
		const tenantBillsList = Object.keys(tenantBills).map(key => tenantBills[key]);

		return (
			<Root>
				<ContentLayout>
					<FormGroup>
						<FormGroupTitle fontSize={'14px'} lineHeight={1.43} marginBottom={'20px'}>
							{isBillInProgress
								? 'Платёж находится в обработке. В зависимости от ситуации отправка платежа может занять от нескольких секунд до нескольких минут. Вы можете закрыть это окно. Когда платёж будет обработан, оплаченный период использования сервиса продлится автоматически.'
								: 'Для вас сформирован счёт на оплату сервиса. Оплата по счёту еще не поступила. Сервис будет активирован при поступлении средств на расчётный счёт.'}
						</FormGroupTitle>
						{!isBillInProgress && tenantBillsList.length > 0 && (
							<PreviewContentLayout>
								<BillingPreviewLayout>
									<BillingPreview type={'PREVIEW'} tenantBill={tenantBillsList[0]} />
								</BillingPreviewLayout>
								<PayButtonLayout>
									{!isSberbank && (
										<RaisedButton
											type={'primary'}
											label={'Оплатить картой'}
											style={buttonStyle}
											fullWidth
											onClick={ev => this.initPaymentRequestForPerson(ev, BillingProvider.YANDEX_KASSA)}
										/>
									)}
									<FastBillForm />
								</PayButtonLayout>
							</PreviewContentLayout>
						)}
					</FormGroup>
					{this.renderRequestedTariffBlock()}
				</ContentLayout>
			</Root>
		);
	};

	renderContentIfUnpaidBillIsNotExist = () => {
		const { billingServiceStatus } = this.props;
		const { activeTariffIndex } = this.state;
		const tariffDescLink = isSberbank
			? 'https://sbbfm.ru/atr-framework-services/login#tariffs'
			: 'https://seeneco.com/ru/tarify/';

		return (
			<Root>
				<MessageCardLayout>
					{!billingServiceStatus.FreemiumMode ? (
						<Alert appearance='warning'>
							Для совершения оплаты за использование сервиса вам необходимо выбрать тариф и период продления, и
							действовать в соответствии с инструкциями, отображаемыми для выбранного способа.
						</Alert>
					) : (
						<Alert appearance='warning'>
							Вы используете бесплатную версию сервиса. Чтобы получить доступ ко всем возможностям сервиса, выберите
							один из тарифов.
						</Alert>
					)}
				</MessageCardLayout>
				<ContentLayout>
					<FormGroup>
						<TitleLayout>
							<FormGroupTitle>Выберите тариф</FormGroupTitle>
							<Link href={tariffDescLink} target='_blank' rel='noopener'>
								Подробное описание тарифов
							</Link>
						</TitleLayout>
						<PickerLayout fullWidth>
							{this.tariffSourceList.length > 0 ? (
								<CardPicker
									dataSource={this.tariffSourceList}
									value={activeTariffIndex}
									itemWidth={'25%'}
									onChange={this.handleSelectTariff}
								/>
							) : (
								<Alert appearance='warning'>
									Для вас не установлены доступные тарифы, обратитесь в службу технической поддержки
								</Alert>
							)}
						</PickerLayout>
					</FormGroup>
					<FormGroup>
						<FormGroupTitle>Выберите период продления</FormGroupTitle>
						<PickerLayout>
							{this.tariffSourceList.length > 0 ? (
								<FlatTabs selectedValue={activeTariffIndex} withoutHeader>
									{this.renderRates()}
								</FlatTabs>
							) : (
								<Alert appearance='warning'>
									Для вас не установлены доступные тарифы, обратитесь в службу технической поддержки
								</Alert>
							)}
						</PickerLayout>
					</FormGroup>
				</ContentLayout>
			</Root>
		);
	};

	renderContentForSyncPaymentStatus = () => {
		const { requestedTariffRate } = this.props;

		return (
			<Root>
				<ContentLayout>
					<FormGroup>
						<FormGroupTitle fontSize={'14px'} lineHeight={1.43} marginBottom={'20px'}>
							<span>
								Счёт отправлен в{'\u00A0'}банк, но{'\u00A0'}информация о{'\u00A0'}подписи платежа по{'\u00A0'}счёту еще
								не{'\u00A0'}поступила. В{'\u00A0'}настоящий момент статус платежа на{'\u00A0'}стороне банка не{'\u00A0'}
								отслеживается и{'\u00A0'}счёт автоматически не{'\u00A0'}будет закрыт при подписи платежа.
								<br />
								Нажмите на{'\u00A0'}кнопку «Отслеживать оплату» для того, чтобы система автоматически начала отслеживать
								статус платежа в{'\u00A0'}банке.
							</span>
						</FormGroupTitle>
					</FormGroup>
					<FormGroup>
						<SyncPaymentLayout>
							<RaisedButton
								type={'primary'}
								label={'Отслеживать оплату'}
								style={buttonStyle}
								onClick={this.handleClickSyncPayment}
							/>
						</SyncPaymentLayout>
					</FormGroup>
					{requestedTariffRate && this.renderRequestedTariffBlock()}
				</ContentLayout>
			</Root>
		);
	};

	render() {
		const { isFetching, isSentToCMS } = this.props;

		if (isFetching) {
			return (
				<LoaderLayout>
					<Loader />
				</LoaderLayout>
			);
		}

		if (isSentToCMS) {
			return this.renderContentForSyncPaymentStatus();
		}

		const isUnpaidBillExists = this.getIsUnpaidBillExists();

		return isUnpaidBillExists ? this.renderContentIfUnpaidBillIsExist() : this.renderContentIfUnpaidBillIsNotExist();
	}
}

const isSberbank = getGlobalConfig().isSberbank;

const getStartMoment = (tenantAccount: TenantAccount) => {
	const expireDate = tenantAccount.ExpireDate;
	const expireDateMoment = moment(expireDate, 'DD-MM-YYYY HH:mm:ss');
	const invalidExpireDate = expireDateMoment.isAfter('2100-01-01') || expireDateMoment.isBefore(moment());

	return invalidExpireDate ? moment() : expireDateMoment;
};

export default SelectConditionsDisplay;
