import * as BrowserHistory from 'history';
import React from 'react';
import { isBrowser } from 'react-device-detect';
import { Router } from 'react-router-dom';

import { getGlobalConfig, getServiceName } from '@config';
import { DECK_ELEMENT_ID } from '@core/hocs/with-top-shift.hoc';
import { ROUTE_MAP } from '@core/routes/urls';
import { subscribeOnFastPayInvoiceProcess } from '@invoice/shared';
import ActionMessage from '@shared/components/action-message';
import ErrorMessage from '@shared/components/error-message';
import { Box } from '@ui/box';
import { Drawer } from '@ui/drawer';
import { ErrorBoundary } from '@ui/error-boundary';
import { Loader } from '@ui/loader';
import { SlideContent } from '@ui/slide-content';
import { SlidePanel } from '@ui/slide-panel';
import { getPropInSafe, setDrawer } from '@utils';
import AppHeader from '../app-header';
import AppMenu from '../app-menu';
import AppProgressBar from '../app-progress-bar';
import AppPurpleBar from '../app-purple-bar';
import Workspace from '../app-workspace/app-workspace.view';
import MobileEntry from '../mobile-entry';
import { BrowserRoot, MobileRoot, Root } from './styled';

const { isSberbank } = getGlobalConfig();
const serviceName = getServiceName();
const isSberbankMobileDevice =
	/Mobile/gi.test(window.navigator.userAgent) && /MPSBBOL/gi.test(window.navigator.userAgent);

export type LayoutViewProps = {
	billingServiceStatus?: BillingServiceStatus;
	billingServiceStatusIsLoaded?: boolean;
	CMSIsLoaded?: boolean;
	fetchSessionVariables?: () => void;
	fetchTenantAccount?: (tenantID: number) => void;
	fetchTenantProfile?: () => void;
	isCurrencyListLoaded?: boolean;
	isFullAccess?: boolean;
	isShellRerenderBlocked: boolean;
	loadSessionContext: () => void;
	loadSessionVariables: () => void;
	routes?: {
		kpi: React.ComponentType<any>;
		workspace: React.ComponentType<any>;
		title: React.ComponentType<any>;
		appMenuLinks?: React.FC<any>;
	};
	sessionContext: ClientSessionContext;
	sessionContextLoaded: boolean;
	sessionVariables: SessionVariable[];
	tenantAccountIsLoaded?: boolean;
	tenantID: number;
	tenantProfile?: TenantProfile;
	tenantProfileIsLoaded?: boolean;
	withoutSession?: boolean;
};

type LayoutViewState = {
	opacity: number;
	continueUsingWebVersion: boolean;
};

export const history = BrowserHistory.createBrowserHistory();

class LayoutView extends React.Component<LayoutViewProps, LayoutViewState> {
	state = {
		opacity: 0,
		continueUsingWebVersion: false,
	};
	unsubscribeFastPayInvoiceProcess = subscribeOnFastPayInvoiceProcess({
		onStart: () => {},
		onEnd: () => {
			this.setState({ opacity: 1 });
			this.unsubscribeFastPayInvoiceProcess && this.unsubscribeFastPayInvoiceProcess();
		},
	});

	shouldComponentUpdate(nextProps: LayoutViewProps) {
		return !nextProps.isShellRerenderBlocked;
	}

	componentDidMount() {
		this.props.loadSessionContext();
	}

	componentWillUnmount() {
		this.unsubscribeFastPayInvoiceProcess();
	}

	componentDidUpdate(prevProps: LayoutViewProps) {
		if (prevProps.sessionVariables !== this.props.sessionVariables) {
			const showTariffSelector = this.props.sessionVariables?.find(
				variable => variable.Name === 'show_tariff_selector',
			);

			if (showTariffSelector?.Value === '1') {
				history.push(`${ROUTE_MAP.TENANT_ACCOUNT}/bills-and-services?needPayment=1`);
			}
		}
	}

	getNeedStartRenderLayout = () => {
		const {
			billingServiceStatusIsLoaded,
			tenantProfileIsLoaded,
			CMSIsLoaded,
			tenantAccountIsLoaded,
			isCurrencyListLoaded,
		} = this.props;
		const needStart =
			this.props.withoutSession ||
			(this.props.sessionContextLoaded &&
				billingServiceStatusIsLoaded &&
				tenantProfileIsLoaded &&
				CMSIsLoaded &&
				tenantAccountIsLoaded &&
				isCurrencyListLoaded);

		return needStart;
	};

	handleKeepOn = () => this.setState({ continueUsingWebVersion: true });

	renderBrowserContent = () => {
		const { billingServiceStatus, tenantProfile, isFullAccess } = this.props;
		const { opacity } = this.state;
		const needStartRenderLayout = this.getNeedStartRenderLayout();

		return (
			<BrowserRoot style={{ opacity }}>
				<ErrorBoundary>
					<Box id={DECK_ELEMENT_ID} position='fixed' top={0} left={0} right={0} width='100%' zIndex={1000}>
						<AppProgressBar />
						<AppPurpleBar />
					</Box>
				</ErrorBoundary>
				<ErrorBoundary>
					<AppHeader
						sessionContext={this.props.sessionContext}
						billingServiceStatus={this.props.billingServiceStatus}
						kpi={needStartRenderLayout && this.props.routes.kpi}
						renderLayout={needStartRenderLayout}
						withoutSession={this.props.withoutSession}
						title={this.props.routes.title}
						isFullAccess={isFullAccess}
					/>
				</ErrorBoundary>
				<ErrorBoundary>
					<Workspace
						sessionContextLoaded={this.props.sessionContextLoaded}
						workspaceComponent={needStartRenderLayout && this.props.routes.workspace}
						sessionContext={this.props.sessionContext}
						billingServiceStatus={billingServiceStatus}
						tenantProfile={tenantProfile}
						isFullAccess={isFullAccess}
					/>
				</ErrorBoundary>
				<ErrorBoundary>
					{needStartRenderLayout && (
						<AppMenu
							appMenuLinks={this.props.routes.appMenuLinks}
							availableComponents={this.props.sessionContext.AvailableComponents}
							userRights={this.props.sessionContext.UserRights}
							tenantProfile={tenantProfile}
							isFullAccess={isFullAccess}
						/>
					)}
				</ErrorBoundary>
				<ErrorBoundary>
					<Drawer ref={setDrawer} />
				</ErrorBoundary>
				<ErrorBoundary>
					<SlideContent.Base ref={SlideContent.setRef} />
				</ErrorBoundary>
				<ErrorBoundary>
					<SlidePanel.Base ref={SlidePanel.setRef} />
				</ErrorBoundary>
				<ErrorBoundary>
					<ActionMessage />
				</ErrorBoundary>
				<ErrorMessage />
			</BrowserRoot>
		);
	};

	renderMobileContent = () => {
		const { sessionContext } = this.props;
		const { continueUsingWebVersion } = this.state;
		const needStartRenderLayout = this.getNeedStartRenderLayout();
		const tenantName = getPropInSafe(sessionContext, o => o.SessionTenant.Name, '');

		if (!isSberbank || continueUsingWebVersion) {
			return this.renderBrowserContent();
		}

		return (
			<MobileRoot>
				{needStartRenderLayout ? (
					<MobileEntry tenantName={tenantName} serviceName={serviceName} onKeep={this.handleKeepOn} />
				) : (
					<Loader.Layout height='300px'>
						<Loader />
					</Loader.Layout>
				)}
			</MobileRoot>
		);
	};

	render() {
		return (
			<Router history={history}>
				<Root>{isBrowser && !isSberbankMobileDevice ? this.renderBrowserContent() : this.renderMobileContent()}</Root>
			</Router>
		);
	}
}

export default LayoutView;
