import React, { memo } from 'react';

import { useMapDispatch, useMapState } from '@core/flux';
import { selectImportDateRange } from '@integrations/selectors';
import { mainIntegrationsActionsPack } from '@integrations/actions';
import { XCmsImportDateRangeSelect, XCmsImportDateRangeSelectProps } from './cms-import-period-select.view';
import { FormAppearance } from './model';

export type CmsImportDateRangeSelectProps = Partial<Pick<XCmsImportDateRangeSelectProps, 'appearance' | 'disabled'>>;

const CmsImportDateRangeSelect: React.FC<CmsImportDateRangeSelectProps> = memo(
	({ appearance = FormAppearance.IMPORT_EXTERNAL, disabled = false }) => {
		const [dateRange] = useMapState([selectImportDateRange]);
		const [setDateRange] = useMapDispatch([mainIntegrationsActionsPack.setImportDateRange]);

		return (
			<XCmsImportDateRangeSelect
				dateRange={dateRange}
				appearance={appearance}
				disabled={disabled}
				setDateRange={setDateRange}
			/>
		);
	},
);

export { CmsImportDateRangeSelect as SelectDate };
