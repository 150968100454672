import { createAsyncAction, createTypes } from '@flux';
import { mainSessionVariablesSelectorsPack } from '@platform/selectors/session-variables.selectors';

type ActionTypes = {
	FETCH_SESSION_VARIABLES: string;
};

const types = createTypes<ActionTypes>(['FETCH_SESSION_VARIABLES'], 'PLATFORM');

const actions = {
	fetchSessionVariables: createAsyncAction(
		types.FETCH_SESSION_VARIABLES,
		api => {
			return new Promise<Array<SessionVariable>>(resolve => {
				api.securityPack.directory.fetchSessionVariables().then(result => {
					resolve(result);
				});
			});
		},
		{
			isValidSelector: mainSessionVariablesSelectorsPack.selectAsyncSessionVariables.selectIsValid,
			isFetchingSelector: mainSessionVariablesSelectorsPack.selectAsyncSessionVariables.selectIsFetching,
		},
	),
};

export const mainSessionVariablesActionsPack = {
	types,
	actions,
};
