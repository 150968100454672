const ALFA_BANK_BNK_CMS = 'ALFA_BANK_BNK_CMS';
const MODULE_BANK_BNK_CMS = 'MODULE_BANK_BNK_CMS';
const OPEN_BANK_BNK_CMS = 'OPEN_BANK_BNK_CMS';
const SBERBANK_BNK_CMS = 'SBERBANK_BNK_CMS';
const TINKOFF_BANK_BNK_CMS = 'TINKOFF_BANK_BNK_CMS';
const TOCHKA_BANK_BNK_CMS = 'TOCHKA_BANK_BNK_CMS';
const URALSIB_BNK_CMS = 'URALSIB_BNK_CMS';
const VTB_BANK_CMS = 'VTB_BANK_CMS';
const YOU_KASSA_CMS = 'YOU_KASSA_CMS';
const ZEN_MONEY_CMS = 'ZEN_MONEY_CMS';

const integrationHelpLinks = {
	[ALFA_BANK_BNK_CMS]: '/help/bfm/#section16',
	[MODULE_BANK_BNK_CMS]: '/help/bfm/#section15',
	[OPEN_BANK_BNK_CMS]: '/help/bfm/#section14',
	[SBERBANK_BNK_CMS]: '/help/bfm/#section19',
	[TINKOFF_BANK_BNK_CMS]: '/help/bfm/#section18',
	[TOCHKA_BANK_BNK_CMS]: '/help/bfm/#section17',
	[URALSIB_BNK_CMS]: '/help/bfm/#section14',
	[VTB_BANK_CMS]: '/help/bfm/#section14',
	[YOU_KASSA_CMS]: '/help/bfm/#section14',
	[ZEN_MONEY_CMS]: '/help/bfm/#section21',
};

const rangedCMSNamesList = [
	ALFA_BANK_BNK_CMS,
	MODULE_BANK_BNK_CMS,
	OPEN_BANK_BNK_CMS,
	SBERBANK_BNK_CMS,
	TINKOFF_BANK_BNK_CMS,
	TOCHKA_BANK_BNK_CMS,
	URALSIB_BNK_CMS,
	VTB_BANK_CMS,
];

const CMSNamesMap = {
	[ALFA_BANK_BNK_CMS]: 'Альфабанк',
	[MODULE_BANK_BNK_CMS]: 'Модульбанк',
	[OPEN_BANK_BNK_CMS]: 'Открытие банк',
	[SBERBANK_BNK_CMS]: 'Сбербанк',
	[TINKOFF_BANK_BNK_CMS]: 'Тинькофф Банк',
	[TOCHKA_BANK_BNK_CMS]: 'Точка Банк',
	[URALSIB_BNK_CMS]: 'Уралсиб банк',
	[VTB_BANK_CMS]: 'ВТБ банк',
};

export {
	ALFA_BANK_BNK_CMS,
	MODULE_BANK_BNK_CMS,
	OPEN_BANK_BNK_CMS,
	SBERBANK_BNK_CMS,
	TINKOFF_BANK_BNK_CMS,
	TOCHKA_BANK_BNK_CMS,
	URALSIB_BNK_CMS,
	VTB_BANK_CMS,
	YOU_KASSA_CMS,
	integrationHelpLinks,
	rangedCMSNamesList,
	CMSNamesMap,
};
